<template>
  <div>
    <template v-if="showPreview">
      <v-card
        class="pa-2 mb-2"
        outlined
        flat
      >
        <text-editor
          v-if="richText"
          class="mt-2"
          :value="value"
          :editable="false"
          @update-words-count="(val) => (wordsCount = val)"
          @update-chars-count="(val) => (charsCount = val)"
        />
        <template v-else>
          <span v-if="showPreviewLabel">
            {{ $t("live.supportMarkdownLatex.preview.label") }}
          </span>
          <katex-md
            :expr="value"
            :linkify="false"
          />
        </template>

        <slot name="bellowPreview" />
      </v-card>

      <v-row
        class="grey--text caption mt-2"
        v-if="richText"
      >
        <v-spacer />
        <v-col cols="auto">
          {{ sentenceLimit() }}
        </v-col>
      </v-row>
    </template>

    <template v-if="showInputField">
      <v-card
        class="mt-2 allow-overflow"
        v-if="richText"
        outlined
        flat
      >
        <text-editor
          :value="value"
          @input="updateValue"
          :placeholder="$t('live.enterPlaceholder')"
          @update-words-count="(val) => (wordsCount = val)"
          @update-chars-count="(val) => (charsCount = val)"
          :limit="limitedContent ? limitCount : null"
          :limit-type="limitType"
        />
      </v-card>
      <template v-else>
        <v-textarea
          solo
          :value="value"
          @input="updateValue"
          :disabled="!answerable"
          :placeholder="$t('live.enterPlaceholder')"
          auto-grow
          autocomplete="off"
          hide-details
          ref="inputField"
        />
        <slot name="bellowContent" />
      </template>

      <v-row class="grey--text caption mt-2">
        <v-col
          cols="auto"
          v-if="!richText"
        >
          <v-icon
            small
            left
            v-tooltip="$t('live.supportMarkdownLatex.tooltip')"
          >
            info_outline
          </v-icon>
          {{ $t("live.supportMarkdownLatex.label") }}
          <a @click="showPreviewDefinedByUser = !showPreviewDefinedByUser">
            {{ previewButtonLabel }}
          </a>
        </v-col>
        <v-spacer />
        <v-col
          cols="auto"
          :class="{ 'error--text': exceedsLimit() }"
        >
          {{ sentenceLimit() }}
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import KatexMd from "../katex-md.vue"
import TextEditor from "../shared/text_editor/text_editor.vue"

export default {
  name: "OpenQuestionField",
  components: { TextEditor, KatexMd },
  props: {
    value: {
      type: String,
      default: "",
    },
    forceShowPreview: {
      type: Boolean,
      default: false,
    },
    showPreviewLabel: {
      type: Boolean,
      default: false,
    },
    answerable: {
      type: Boolean,
      default: false,
    },
    showInputField: {
      type: Boolean,
      default: true,
    },
    limitedContent: {
      type: Boolean,
      default: false,
    },
    limitCount: {
      type: Number,
      default: 0,
    },
    limitType: {
      type: String,
      default: "",
    },
    richText: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showPreviewDefinedByUser: false,
    wordsCount: 0,
    charsCount: 0,
  }),
  computed: {
    showPreview() {
      return this.showPreviewDefinedByUser || this.forceShowPreview
    },
    previewButtonLabel() {
      return this.showPreview
        ? this.$t("live.supportMarkdownLatex.preview.hide")
        : this.$t("live.supportMarkdownLatex.preview.show")
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value)
    },
    exceedsLimit() {
      if (!this.limitedContent) return false

      if (this.limitType === "words") {
        return this.wordsCount >= this.limitCount
      } else {
        return this.charsCount >= this.limitCount
      }
    },

    sentenceLimit() {
      if (!this.limitedContent)
        return `${
          this.wordsCount +
          " " +
          this.$t("quiz.editor.wordsLimit.words").toLowerCase()
        }`

      let limitedType = this.limitType
      let limit = this.limitCount

      if (limitedType === "words") {
        return `${
          this.wordsCount +
          "/" +
          limit +
          " " +
          this.$t("quiz.editor.wordsLimit.words").toLowerCase()
        }`
      } else {
        return `${
          this.charsCount +
          "/" +
          limit +
          " " +
          this.$t("quiz.editor.wordsLimit.charactersShort")
        }`
      }
    },
  },
  watch: {
    includesRichTextPreview(newValue, oldValue) {
      if (this.richText) return

      if (newValue && !oldValue) {
        this.showPreviewDefinedByUser = true
      }
    },
  },
}
</script>

<style scoped></style>
