<template>
  <v-card
    class="mb-2"
    flat
    outlined
  >
    <div
      class="pa-2 default-border"
      :class="statusClass"
    >
      <slot />
      <div
        v-if="correctionMode"
        class="choice-status"
      >
        {{ hint }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ChoiceCorrectionWrapper",
  props: {
    correctionMode: { type: Boolean, default: false },
    correct: { type: Boolean, default: null },
    isSelected: { type: Boolean, default: false },
  },
  computed: {
    statusClass() {
      let statusClasses = []
      if(this.correctionMode) {
        if(this.correct)                     statusClasses.push("correctContainer")
        if(!this.correct && this.isSelected) statusClasses.push("wrongContainer")
      } else {
        if(this.isSelected)                  statusClasses.push("selectedChoiceContainer")
      }

      return statusClasses.join(" ")
    },
    hint() {
      return this.correct ? this.$t("live.correctChoice") : this.$t("live.wrongChoice")
    },
  },
}
</script>

<style scoped>
  .choice-status {
    margin-left: 34px;
    font-size: 10px;
  }
  .default-border {
    border: 2px solid transparent;
  }
</style>

<style>

.quiz_question_choice .v-input {
  margin-top: 0;
  padding: 0;
}

.correctContainer {
  border: 2px solid #4CAF50 !important;
}

.wrongContainer {
  border: 2px solid #F44336 !important;
}

.selectedChoiceContainer {
  /*background: #cde9f5;*/
  border: 2px solid #1976d2 !important;
}

</style>
