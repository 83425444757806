<template>
  <v-alert
    :type="alertType"
    v-if="(outOfTimeAnswer || rejectedAnswer) && currentUser.teacher"
    :icon="false"
    text
    compact
  >
    <v-row
      align="center"
      class="mb-0"
    >
      <v-col class="grow">
        <span v-html="$t('live.correction.outOfTime.label', {date: expectedEndDateSentence, receivedDate: answerCreatedAt, delay: delay})" />
        <span v-if="rejectedAnswer">
          {{ $t('live.correction.outOfTime.refusedAnswer') }}
        </span>
      </v-col>
      <v-col class="shrink">
        <v-btn
          :color="buttonColor"
          depressed
          @click="acceptAnswer"
        >
          <v-icon
            left
            v-html="'mdi-thumb-up-outline'"
          />
          {{ $t('live.correction.outOfTime.buttons.accept') }}
        </v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn
          v-if="outOfTimeAnswer"
          :color="buttonColor"
          text
          @click="rejectAnswer"
        >
          <v-icon
            left
            v-html="'mdi-thumb-down-outline'"
          />
          {{ $t('live.correction.outOfTime.buttons.reject') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapActions } from "vuex"
import questionStateMixin from "./question_state_mixin"
import instanceScoreDuration from "../quiz/evalmee/instance_score_duration"

export default {
  name: "OutOfTimeAlert",
  mixins: [questionStateMixin, instanceScoreDuration],
  props: {
    answer: { type: Object, required: true },
    currentUser: { type: Object, required: true },
    quizInstance: {
      type: Object,
      default: null,
    },
    quiz: {
      type: Object,
      required: true,
    },
    score: {
      type: Object,
      required: true,
    },
  },
  computed: {
    answerCreatedAt() {
      return this.$moment(this.answer.created_at).format((`LTS [${this.$t("live.correction.outOfTime.of")}] L`))
    },
    expectedEndDateSentence() {
      if(!this.quizInstance) return

      return this.$moment(this.expectedEndDate)
        .format((`LTS [${this.$t("live.correction.outOfTime.of")}] L`))
    },
    expectedEndDate() {
      return this.score.finished_at
    },
    delay() {
      return this.$moment.duration(this.$moment(this.answer.created_at) - this.$moment(this.expectedEndDate))
        .format("H[h] m[min] ss[s]", { trunc: true })
    },
    alertType() {
      return this.outOfTimeAnswer ? "warning" : ""
    },
    buttonColor() {
      return this.outOfTimeAnswer ? "orange" : ""
    },
  },
  methods: {
    ...mapActions(["handleEventAnswer"]),
    acceptAnswer(){
      this.handleEventAnswer({ answer: this.answer, status: "accepted" })
        .then(this.reloadScore)
    },
    rejectAnswer(){
      this.handleEventAnswer({ answer: this.answer, status: "rejected" })
        .then(this.reloadScore)
    },
    reloadScore(){
      this.$emit("reloadScore")
    },
  },
}
</script>
