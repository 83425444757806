<template>
  <evalmee-chip
    small
    label
    color="red"
    dark
    v-if="showChip"
    v-tooltip="content.tooltip"
  >
    {{ content.label }}
  </evalmee-chip>
</template>

<script>
import questionStateMixin from "./question_state_mixin"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"

export default {
  name: "OutOfTimeStudentChip",
  components: { EvalmeeChip },
  mixins: [questionStateMixin],
  props: {
    answer: { type: Object, required: true },
    currentUser: { type: Object, required: true },
  },
  computed: {
    showChip() {
      return (this.outOfTimeAnswer || this.rejectedAnswer) &&
        !this.currentUser.teacher
    },
    content() {
      if (this.outOfTimeAnswer) {
        return {
          label: "Hors délai",
          tooltip: "Cette réponse a été envoyée après la fermeture du quiz. Elle sera acceptée manuellement par votre enseignant.",
        }
      } else if (this.rejectedAnswer) {
        return {
          label: "Refusée",
          tooltip: "Cette réponse a été envoyée après la fermeture du quiz et a été refusée par votre enseignant.",
        }

      }
    },
  },
}
</script>
