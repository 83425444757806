import { diffChars } from "diff"
import EventsMixin from "../shared/events_mixin"
import lodashReplacementHelper from "../../helpers/lodash_replacement_helpers"
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"

const fastInsertTrigger = 15 // ms

export default {
  mixins: [EventsMixin],
  data: () => ({
    lastContentUpdate: null,
    fastInsertAddedContent: "",
    fastInsertDiff: {},
    fastInsertRegisterEventDebounce: null,
    lastQuestionId: null,
    contentInPreviousTimeFrame: "",
  }),
  methods: {
    questionHasChanged() {
      if(this.lastQuestionId !== this.question.id){
        this.lastQuestionId = this.question.id
        return true
      }
      this.lastQuestionId = this.question.id
      return false
    },

    detectBulkInsertion(newValue, oldValue){
      if(this.questionHasChanged()) return

      const diff = this.textDiff(newValue, oldValue)
      const addedLength = diff.addedText.length

      if (addedLength < 2) return
      useStudentEventRegisterer(this.quiz.id, this.quizzesAttemptSummary.id)
        .register({
          event: "bulk_insert",
          exerciseId: this.exerciseId,
          details: {
            content: diff.addedText,
            addedText: diff.addedText,
            removedText: diff.removedText,
            diff: diff.diff,
          },
        })
    },

    textDiff(newValue, oldValue) {
      const sanitizedNewValue = newValue.replace(/(<([^>]+)>)/gi, "")
      const sanitizedOldValue = oldValue.replace(/(<([^>]+)>)/gi, "")

      const diff = diffChars(sanitizedOldValue, sanitizedNewValue)

      const addedText = diff.filter(d => d.added).map(d => d.value).join("")
      const removedText = diff.filter(d => d.removed).map(d => d.value).join("")
      return { addedText, removedText, diff }
    },

    detectFastInsert(newValue) {
      if(this.questionHasChanged()) return
      if(!this.lastContentUpdate) {
        this.contentInPreviousTimeFrame = newValue
        this.lastContentUpdate = new Date()
        return
      }

      const now = new Date()
      const timeDiff = now - this.lastContentUpdate

      if (timeDiff > fastInsertTrigger) {
        this.contentInPreviousTimeFrame = newValue
      } else {
        const diff = this.textDiff(newValue, this.contentInPreviousTimeFrame)
        this.fastInsertAddedContent = diff.addedText
        this.fastInsertDiff = diff.diff
        this.fastInsertRegisterEventDebounce()
      }

      this.lastContentUpdate = new Date()
    },
    registerFastInsertEvent() {
      if (this.fastInsertAddedContent.length < 2) return

      useStudentEventRegisterer(this.quiz.id, this.quizzesAttemptSummary.id)
        .register({
          event: "fast_insert",
          exerciseId: this.exerciseId,
          details: {
            content: this.fastInsertAddedContent,
            diff: this.fastInsertDiff,
          },
        })
      this.fastInsertAddedContent = ""
    },
  },
  mounted() {
    this.fastInsertRegisterEventDebounce = lodashReplacementHelper.debounce(this.registerFastInsertEvent, 500)
  },

}
