export default {
  computed: {
    outOfTimeAnswer() {
      return  ["out_of_time", "timer_timeout"].includes(this.answer?.status)
    },
    rejectedAnswer() {
      return this.answer?.status === "rejected"
    },
  },
}
