import ws from "../../web_sockets"
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"

const events = [
  { name: "mouse_leave", status: { name: "mouseInDocument", value: false } },
  { name: "mouse_enter", status: { name: "mouseInDocument", value: true } },
  { name: "loose_focus", status: { name: "documentInFocus", value: false } },
  { name: "get_focus", status: { name: "documentInFocus", value: true } },
  { name: "fullscreen_enter", status: { name: "fullscreen", value: true } },
  { name: "fullscreen_leave", status: { name: "fullscreen", value: false } },
  {
    name: "early_correction_access",
    status: { name: "earlyCorrectionAccess", value: false },
  },
  { name: "back_online", status: { name: "backOnline", value: false } },
  { name: "offline", status: { name: "offline", value: false } },
  { name: "paste", status: { name: "paste", value: false } },
  { name: "copy", status: { name: "copy", value: false } },
  { name: "cut", status: { name: "cut", value: false } },
  { name: "resize_browser", status: { name: "resizeBrowser", value: false } },
  { name: "page_load", status: { name: "pageLoad", value: false } },
  { name: "enter_exam", status: { name: "enterExam", value: false } },
  { name: "leave_exam", status: { name: "leaveExam", value: false } },
  { name: "bulk_insert", status: { name: "bulkInsert", value: false } },
  { name: "fast_insert", status: { name: "fastInsert", value: false } },
]

export default {
  methods: {
    registerEvent({ event, exerciseId, details } = {
      exerciseId: this.currentExercise?.id,
      details: {},
    }) {
      useStudentEventRegisterer(this.quiz.id, this.quizzesAttemptSummary.id)
        .register({ event, exerciseId, details })
    },
    startEventSubscription(quizId) {
      return ws.channels.QuizStudentEvents.subscribe({ quizId: quizId })
    },
  },
  computed: {
    events() {
      return events
    },
  },
}
