<template>
  <div>
    <!--    keep this input outside any condition as it is referenced at mount-->
    <input
      accept="*"
      type="file"
      :multiple="multiple"
      ref="input"
      style="display: none"
      :disabled="disabled"
    >
    <v-card>
      <template v-if="showPreview">
        <div class="ma-2">
          <v-sheet
            v-show="true"
            rounded
            class="drop-zone pa-2"
            color="gray darken-2"
            :class="{
              dragging: isDragging,
              gray: isDragging,
            }"
            @dragover.prevent="dragover"
            @dragenter.prevent="dragover"
            @drop.prevent.stop="onDrop"
            @dragleave.prevent="dragleave"
          >
            <div :class="{ hidden: uploadInProgress }">
              <answer-uploads-preview
                v-if="answer && hasAttachements"
                :answer="answer"
                :correction-mode="disabled"
                @upload="$refs.input.click()"
                :can-upload="allowComputerUpload"
              />

              <dropzone-placeholder
                v-if="!hasAttachements && allowComputerUpload"
                :title="$t('dropFile.title', { fileType: '' })"
                :subtitle="$t('dropFile.subtitle')"
                class="center"
                @click="$refs.input.click()"
                style="cursor: pointer"
              />
            </div>
            <div
              class="list-group"
              v-if="uploads.length > 0"
            >
              <div
                class="list-group-item"
                v-for="(file, index) in uploads"
                :key="index"
              >
                <v-progress-circular
                  size="20"
                  indeterminate
                />
                Uploading: {{ file.file.filename }}<br>
              </div>
            </div>
          </v-sheet>
        </div>
        <v-divider />
      </template>
      <div class="ma-2">
        <v-row class="mx-0">
          <v-btn
            text
            small
            v-if="!showPreview && allowComputerUpload"
            @click="forceShowPreview = true"
            :disabled="disabled"
          >
            <v-icon left>
              mdi-attachment
            </v-icon>
            {{ $t("live.question.open.upload.common.sendAPicture") }}
          </v-btn>
          <slot name="buttons" />
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { DirectUpload } from "activestorage"
import DropzonePlaceholder from "../shared/DropzonePlaceholder.vue"
import AnswerUploadsPreview from "./answer_uploads_preview.vue"

export default {
  name: "AnswerFileUpload",
  components: { AnswerUploadsPreview, DropzonePlaceholder },
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    answer: {
      type: [Object, undefined],
      required: true,
    },
    question: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      forceShowPreview: false,
      isDragging: false,
      progress: 0,
      uploads: [],
    }
  },
  mounted() {
    this.input.addEventListener("change", () => {
      Array.from(this.input.files).forEach((file) => this.upload(file))
      this.input.value = null
    })
  },
  methods: {
    dragover() {
      if (!this.allowComputerUpload) return

      this.isDragging = true
    },
    dragleave() {
      if (!this.allowComputerUpload) return

      this.isDragging = false
    },
    onDrop(event) {
      if (!this.allowComputerUpload) return

      this.dragleave()
      const files = event.dataTransfer.files
      Array.from(files).forEach((file) => this.upload(file))
    },
    onProgress(event) {
      this.progress = (event.loaded / event.total) * 100
    },
    upload(file) {
      if (!this.allowComputerUpload) return

      const upload = new DirectUpload(file, this.uploadUrl, this)
      this.uploads.push({ file, upload })
      this.progress = 0
      upload.create((error, blob) => {
        if (error) {
          // TODO
        } else {
          this.uploads = this.uploads.filter(
            (payload) => payload.file.filename !== file.filename
          )
          this.$emit("upload", { file, blob })
        }
      })
    },
    openFileBrowser() {
      this.input.click()
    },
  },
  computed: {
    uploadInProgress() {
      return this.uploads.length > 0
    },
    input() {
      return this.$refs.input
    },
    showPreview() {
      if (this.forceShowPreview) return true
      return this.hasAttachements
    },
    hasAttachements() {
      return this.answer?.pictures?.length > 0
    },
    notOnlyImages() {
      // For legacy questions in drafts, allow all uploads
      if (!this.question?.attributes.attachment_restrictions) return true

      return !this.question?.attributes.attachment_restrictions.includes(
        "image"
      )
    },
    allowComputerUpload() {
      return this.notOnlyImages
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-zone {
  min-height: 50px;
  border-radius: 5px;
  border: 2px dashed #c8c8c8;
}
.drop-zone.dragging {
  border: 2px dashed #313131;
  position: relative;
}
input[type="file"][data-direct-upload-url][disabled] {
  display: none;
}
</style>
