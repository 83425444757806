<template>
  <v-simple-table dense>
    <tbody>
      <tr>
        <th>Sync:</th>
        <td>{{ answer.synchronized }}</td>
        <td>During exam only</td>
      </tr>
      <tr>
        <th>Edited:</th>
        <td>{{ answer.edited }}</td>
        <td>During exam only</td>
      </tr>
      <tr>
        <th>Last Edit:</th>
        <td>{{ formatTimeDateWithSeconds(answer.last_edit_at) }}</td>
        <td>Student computer time</td>
      </tr>
      <tr>
        <th>Created at:</th>
        <td>{{ $moment(answer.created_at).format("ll LTS") }}</td>
        <td>Server time</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>

import timeHelpers from "@/helpers/time_helpers"

export default {
  name: "AnswerDebugInfo",
  props: {
    answer: { type: Object, required: true },
  },
  methods: {
    ...timeHelpers,
  },
}
</script>
