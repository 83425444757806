<template>
  <v-card
    flat
    tile
  >
    <div class="pa-2">
      <div class="d-flex align-center">
        <eva-big-icon
          :icon="answerComment ? 'mdi-account' : 'check'"
          size="20"
          color="blue"
        />
        <span class="subtitle-2 ml-2">
          {{ answerComment ? $t('live.correction.answer.customFeedback.label') : $t('live.correction.answer.genericFeedback.label') }}
        </span>
        <v-spacer />

        <evalmee-chip
          color="blue"
          small
          label
          icon="mdi-creation-outline"
          class="ma-0"
          v-if="aiGenerated"
        >
          {{ $t('live.correction.generalFeedback.aiGenerated') }}
        </evalmee-chip>
      </div>
    </div>
    <v-divider />
    <v-card-text class="ml-5">
      <katex-md :expr="content" />
    </v-card-text>
  </v-card>
</template>
<script>

import KatexMD from "../katex-md.vue"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"
import EvaBigIcon from "@/components/shared/eva-big-icon.vue"

export default {
  name: "QuestionCorrectionComment",
  components: { EvaBigIcon, EvalmeeChip, "katex-md": KatexMD },
  props: {
    content: { type: String, default: "" },
    answerComment: { type: Boolean, default: false },
    aiGenerated: { type: Boolean, default: false },
  },
}
</script>
