<template>
  <div>
    <template v-if="multiChoices">
      <choice-correction-wrapper
        v-for="(choice) in choices"
        :key="choice.id || choice.uuid"
        :correction-mode="correctionMode"
        :correct="choice.attributes.correct"
        :is-selected="value.includes(choice.id)"
      >
        <v-checkbox
          :value="choice.id"
          v-model="selectedChoicesMultiples"
          multiple
          @click="emitUpdate"
          :disabled="correctionMode"
          :readonly="previewMode"
          hide-details
          class="mt-0 pt-0"
        >
          <template #label>
            <katex-md
              :expr="choice.attributes.name"
              :linkify="false"
            />
          </template>
        </v-checkbox>
      </choice-correction-wrapper>
    </template>

    <v-radio-group
      v-model="selectedChoiceSingle"
      v-else
      hide-details
      class="mt-0"
      :multiple="singleChoiceAsMultiChoices"
    >
      <choice-correction-wrapper
        v-for="choice in choices"
        :key="choice.id || choice.uuid"
        :correction-mode="correctionMode"
        :correct="choice.attributes.correct"
        :is-selected="value.includes(choice.id)"
      >
        <v-radio
          :label="choice.attributes.name"
          :value="choice.id"
          @click="() => {if(!previewMode) {removeRadioChoice(choice.id) ; emitUpdate()}}"
          :disabled="correctionMode"
          :readonly="previewMode"
        >
          <template #label>
            <katex-md
              :expr="choice.attributes.name.toString()"
              :linkify="false"
            />
          </template>
        </v-radio>
      </choice-correction-wrapper>
    </v-radio-group>

    <v-expand-transition>
      <div
        class="text-subtitle red--text mt-4"
        v-if="errors.length > 0"
      >
        {{ errors.join(', ') }}
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import KatexMd from "../katex-md.vue"
import Vue from "vue"
import ChoiceCorrectionWrapper from "./choice_correction_wrapper.vue"

export default {
  name: "QuestionChoices",
  components: { ChoiceCorrectionWrapper, KatexMd },
  props: {
    value: { type: Array },
    choices: { type: Array },
    multiChoices: Boolean,
    disabled: Boolean,
    correctionMode: { type: Boolean, default: false },
    previewMode: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
  },
  data: () => ({
    selectedChoices: [],
    selectedChoicesMultiples: {},
  }),

  computed: {
    singleChoiceAsMultiChoices() { return this.correctionMode},
    selectedChoiceSingle: {
      get() {
        return this.singleChoiceAsMultiChoices ? this.value : this.value[0]
      },
      set(val) {
        this.selectedChoices = [val]
      },
    },
  },
  methods: {
    emitUpdate() {
      Vue.nextTick(() => {
        this.$emit("input", this.selectedChoices)
        this.$emit("change", this.selectedChoices)
      })
    },
    removeRadioChoice(val) {
      if(this.selectedChoiceSingle === val) {
        this.selectedChoiceSingle = null
      }
    },
  },
  watch: {
    selectedChoicesMultiples: {
      handler(val) {
        this.selectedChoices = val
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.selectedChoicesMultiples = [...val]
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
