<template>
  <div style="height: 100%">
    <v-btn
      class="ma-1 trash"
      fab
      dark
      x-small
      @click.stop="deletePicture"
      color="error"
      v-if="!correctionMode"
    >
      <v-icon
        dark
        small
      >
        mdi-trash-can-outline
      </v-icon>
    </v-btn>

    <template v-if="errorLoadingImage">
      <eva-file-card
        :name="filename"
        downloadable
        @download="download"
      />
    </template>

    <v-img
      v-else
      :src="picture.link"
      class="img-responsive"
      @click="click"
      @error="errorLoadingImage = true"
      width="100%"
      height="100%"
    >
      <template #placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate />
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<script>
import axiosHelpers from "../../helpers/axios_helpers"
import EvaFileCard from "../shared/eva-file-card.vue"

export default {
  name: "AnswerUploadPreview",
  components: { EvaFileCard },
  props: {
    picture: {
      type: Object,
      required: true,
    },
    correctionMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorLoadingImage: false,
  }),
  methods: {
    deletePicture() {
      this.$emit("delete")
    },
    click() {
      this.$emit("select")
    },
    download() {
      axiosHelpers.downloadFile({
        url: this.picture.link,
        filename: this.filename,
      })
    },
  },
  computed: {
    filename() {
      return this.picture?.link?.split("/").reverse()[0]
    },
  },
}
</script>

<style scoped>
.img-responsive {
  width: 100%;
}

.trash {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
}

.flex-end {
  align-self: flex-end;
}
</style>
